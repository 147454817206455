.btn:disabled:not([data-loading]) {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn[aria-disabled="true"]:not([data-loading]) {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn[data-loading] {
  position: relative;
}

.btn[data-loading] .button-spinner {
  height: 1.25rem;
  width: 1.25rem;
  border-style: solid;
  border-width: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: spin 0.6s infinite;
  animation-timing-function: linear;
  border-radius: 100px;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
