.ProseMirror .hide {
  display: none;
}

.ProseMirror .doc-cut-spacer {
  padding-bottom: 2rem;
  margin-top: 3rem;
}

.ProseMirror .doc-cut-spacer:after {
  content: "■";
  font-size: 0.875rem;
  color: gray;
  text-align: center;
  font-weight: normal;
  position: absolute;
  left: 0;
  width: 100%;
}

/* somethings these <br/>s show up after cuts */
.ProseMirror .doc-cut-spacer ~ br {
  display: none;
}
