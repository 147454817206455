@keyframes float-in {
  from {
    opacity: 0;
    transform: translateY(2.5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    top: 100px;
  }
  to {
    top: 0px;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide-from-bottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
