a.search-dialog-item:focus > div {
  color: black;
  background-color: #cccccc;
  border-bottom-color: #aaaaaa;
}

.search-spinner {
  height: 1.25rem;
  width: 1.25rem;
  border-style: solid;
  border-width: 3px;
  position: absolute;
  top: 50%;
  right: 1rem;
  animation: spin 0.6s infinite;
  animation-timing-function: linear;
  border-radius: 100px;
}

.search-result b {
  color: #111111; /* near-black */
}
