.ProseMirror .find-highlight {
  background: linear-gradient(rgba(255, 215, 0, 0.8), rgba(255, 215, 0, 0.8));
}

.ProseMirror .find-highlight-focused {
  background: linear-gradient(rgba(255, 183, 0, 0.8), rgba(255, 183, 0, 0.8));
}

.ProseMirror .find-highlight.find-highlight-focused {
  background: linear-gradient(rgba(255, 215, 0, 0.8), rgba(255, 215, 0, 0.8)),
    linear-gradient(rgba(255, 183, 0, 0.8), rgba(255, 183, 0, 0.8));
}

.ProseMirror .editor-selected {
  background: linear-gradient(
    rgba(150, 204, 255, 0.8),
    rgba(150, 204, 255, 0.8)
  );
}

.ProseMirror .find-highlight.editor-selected {
  background: linear-gradient(rgba(255, 215, 0, 0.8), rgba(255, 215, 0, 0.8)),
    linear-gradient(rgba(150, 204, 255, 0.8), rgba(150, 204, 255, 0.8));
}

.ProseMirror .find-highlight-focused.editor-selected {
  background: linear-gradient(rgba(255, 183, 0, 0.8), rgba(255, 183, 0, 0.8)),
    linear-gradient(rgba(150, 204, 255, 0.8), rgba(150, 204, 255, 0.8));
}

.ProseMirror .find-highlight.find-highlight-focused.editor-selected {
  background: linear-gradient(rgba(255, 215, 0, 0.8), rgba(255, 215, 0, 0.8)),
    linear-gradient(rgba(255, 183, 0, 0.8), rgba(255, 183, 0, 0.8)),
    linear-gradient(rgba(150, 204, 255, 0.8), rgba(150, 204, 255, 0.8));
}
.ProseMirror:not(:focus-within) .find-highlight {
  background: linear-gradient(rgba(255, 215, 0, 0.8), rgba(255, 215, 0, 0.8));
}

.ProseMirror:not(:focus-within) .find-highlight-focused {
  background: linear-gradient(rgba(255, 183, 0, 0.8), rgba(255, 183, 0, 0.8));
}

.ProseMirror:not(:focus-within) .find-highlight.find-highlight-focused {
  background: linear-gradient(rgba(255, 215, 0, 0.8), rgba(255, 215, 0, 0.8)),
    linear-gradient(rgba(255, 183, 0, 0.8), rgba(255, 183, 0, 0.8));
}

.ProseMirror:not(:focus-within) .editor-selected {
  background: linear-gradient(
    rgba(204, 204, 204, 0.8),
    rgba(204, 204, 204, 0.8)
  );
}

.ProseMirror:not(:focus-within) .find-highlight.editor-selected {
  background: linear-gradient(rgba(255, 215, 0, 0.8), rgba(255, 215, 0, 0.8)),
    linear-gradient(rgba(204, 204, 204, 0.8), rgba(204, 204, 204, 0.8));
}

.ProseMirror:not(:focus-within) .find-highlight-focused.editor-selected {
  background: linear-gradient(rgba(255, 183, 0, 0.8), rgba(255, 183, 0, 0.8)),
    linear-gradient(rgba(204, 204, 204, 0.8), rgba(204, 204, 204, 0.8));
}

.ProseMirror:not(:focus-within)
  .find-highlight.find-highlight-focused.editor-selected {
  background: linear-gradient(rgba(255, 215, 0, 0.8), rgba(255, 215, 0, 0.8)),
    linear-gradient(rgba(255, 183, 0, 0.8), rgba(255, 183, 0, 0.8)),
    linear-gradient(rgba(204, 204, 204, 0.8), rgba(204, 204, 204, 0.8));
}
