.ProseMirror ::selection {
  background-color: transparent;
}

.ProseMirror li {
  /* overriding prosemirror defaults - this borks stripe positioning*/
  position: unset !important;
}

.ProseMirror li::marker {
  font-size: 1rem;
  color: #333333; /* tachyons dark-gray */
}
