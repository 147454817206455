/* only show loading spinner if user has waited about 1s*/
.spinner {
  animation: appear-slowly 1.25s;
}

@keyframes appear-slowly {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spinner > div {
  animation: stripe 2.5s infinite ease-in-out;
  clip-path: inset(0 0 100% 0 round 0.125rem);
}

.spinner .bar-1 {
  animation-delay: 1s;
  transform: translateY(0%);
}

.spinner .bar-2 {
  animation-delay: 1.4s;
  transform: translateY(25%);
}

.spinner .bar-3 {
  animation-delay: 1.8s;
  transform: translateY(12.5%);
}

@keyframes stripe {
  0% {
    clip-path: inset(0 0 100% 0 round 0.125rem);
  }
  10% {
    clip-path: inset(0 0 50% 0 round 0.125rem);
  }
  50% {
    clip-path: inset(0 0 50% 0 round 0.125rem);
  }
  60% {
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    clip-path: inset(50% 0 50% 0);
  }
}
