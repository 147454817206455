/* Global CSS, including Tachyons overrides, goes here */
html,
select option {
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

/* https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */
.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --focus-color: #357edd; /* tachyons blue */
}

.thin-scrollbar {
  scrollbar-width: thin; /* ff */
  scrollbar-color: #aaa #ccc;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 12px; /* chrome et al */
  background-color: #ccc;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

/* extending tachyons a bit */
.hide-child:focus-within .child {
  opacity: 1;
}

.hide-child .child {
  transition: none !important;
}
